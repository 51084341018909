import { Component, OnInit } from '@angular/core';
import {  ModalController, AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { ApiService } from 'src/app/services/api.service';
import swal from '../../../assets/packages/sweetalert';
declare var AWS: any;
declare var require: any;

@Component({
  selector: 'app-myprofile',
  templateUrl: './myprofile.component.html',
  styleUrls: ['./myprofile.component.scss'],
})
export class MyProfileComponent implements OnInit {
  type='password';
  submit=false;
  username:any='';
  user_id:any='';
  profile_data:any;
  first_name: any = '';
  last_name: any = '';
  email: any = '';
  phone_number: any = '';
  update=false;
  process=false;
  validatefname = true;
  validatelname = true;
  validateemail = true;
  validphone = true;
  errortext='';
  disabled=true;
  showerr=true;
  errormsg:any='';
  complete=false;
  prev_fname:any='';
  prev_lname:any='';
  prev_phone:any='';
  constructor(private modalCtrl:ModalController, private router:Router, public apiservice:ApiService, private alertCtrl:AlertController) { }

  ngOnInit() {}

  ionViewDidEnter(){
    let usercredentials = JSON.parse(localStorage.getItem("userData"));
    this.user_id=usercredentials[0].ID;
    this.get_Profileinfo();
    let elemt = document.getElementById('showloader_profile');
    elemt.style.display = 'block';
    }
  
  update_profile(){
    this.submit=true;
    if(this.validateemail && this.validphone && this.first_name!=''  && this.validatefname && this.last_name!='' && this.validatelname && this.email!=''  && this.phone_number!=''){
       this.update_Profileinfo();
    } 
  }

  //disacard changes code starts
  closeModal(){
    this.modalCtrl.dismiss();
  }

  modal_close(){
    if(this.first_name==this.prev_fname && this.last_name==this.prev_lname && this.phone_number==this.prev_phone){
      this.closeModal();
    }else{
      this.close_profile_modal();
    }
 
  }

  async close_profile_modal(){
    await this.alertCtrl.create({
      subHeader: 'Discard unsaved changes?',
      cssClass:'alertlog',
      
      buttons: [
      {
      text: "Cancel",
      },
      {
      text: "Discard", handler: (res) => {
        this.modalCtrl.dismiss();
        this.closeModal();
      }
      }
      ]
      }).then(res => res.present());
    }
   //disacard changes code ends


  get_Profileinfo(): void {
    let req='accounts-and-settings?id='+this.user_id;
    //(req);
     this.apiservice.apicall(req)
        .subscribe(
          resultArray => {
              let res:any=[];
              res=resultArray;
              
              this.profile_data = JSON.parse(res.body);
             //(this.profile_data);
             if(this.profile_data.length!=0){
              this.first_name=this.profile_data[0].first_name;
              this.last_name=this.profile_data[0].last_name;
              this.email=this.profile_data[0].email;
              this.prev_fname=this.profile_data[0].first_name;
              this.prev_lname=this.profile_data[0].last_name;
             
              if(this.profile_data[0].phone_number!=null && this.profile_data[0].phone_number!='' && this.profile_data[0].phone_number!= undefined){
              let phone=this.profile_data[0].phone_number;
            
              this.phone_number=phone.replace("+1", "");
              this.prev_phone=this.phone_number;
              }
             }
             let elemt = document.getElementById('showloader_profile');
             elemt.style.display = 'none';
             this.showerr=false;
          },
          error => {
            //(error);
            this.showerr=true;
            //this.errormsg='Database error. Please try again later.';
            this.errormsg =  error['error'].msg;
            let elemt = document.getElementById('showloader_profile');
            elemt.style.display = 'none';
          }
        )
    }
  
update_Profileinfo(): void {
  this.process=true;
  this.complete=false;
  let addres=encodeURIComponent(this.first_name);
  let phoneno= '%2B1'+this.phone_number;
let f_name=encodeURIComponent(this.first_name);
let l_name=encodeURIComponent(this.last_name);
  let req='accounts-and-settings?first_name='+f_name+'&id='+this.user_id+'&last_name='+l_name+'&phone_number='+phoneno;
  
  //(req);
   this.apiservice.apicallupdate(req)
      .subscribe(
        resultArray => {
            
            this.process=false;
            this.complete=true;
            setTimeout(()=>{
              this.modalCtrl.dismiss();
            }, 1000)
          
           //(resultArray);
           this.showerr=false;
        },
        error => {
          //(error);
         this.process=false;
         this.showerr=true;
        // this.errormsg='Database error. Please try again later.';
        this.errormsg =  error['error'].msg;
        }
      )
  }

  email_validate() {
    if (this.email !== '') {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
        this.validateemail = true;
      } else {
        this.validateemail = false;
      }
    }
  }
  phone_validate(){
    if (this.phone_number != '' && this.phone_number != null) {
      let phone_number = this.phone_number.toString();
      if (/^\d*$/.test(this.phone_number) && (this.phone_number.length >= 10 && this.phone_number.length <= 16) && (/^(?=.*\d)(?=.*[1-9]).{1,16}$/.test(this.phone_number))) {


        this.validphone = true;
      } else {

        this.validphone = false;
      }
    } else {
      this.validphone = true;
    }
  }

  close(){
  this.modalCtrl.dismiss()
  }

  cancel(){
    
    this.modalCtrl.dismiss()
  }

   forgot_password() {
    this.modalCtrl.dismiss()
    this.router.navigate(['/forgot-password']);
  }

  
  }

