import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController, NavController, ModalController } from '@ionic/angular';
import { ChangePasswordComponent } from 'src/app/modals/change-password/change-password.component';
import { MyProfileComponent } from 'src/app/modals/myprofile/myprofile.component';
import { ApiService } from 'src/app/services/api.service';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  user_type:any='';
  constructor(private service:ApiService, private router:Router, private popover:PopoverController, public navCtrl:NavController, public modalController:ModalController) {
    let usercredentials = JSON.parse(localStorage.getItem("userData"));
    this.user_type = usercredentials[0].user_type;
   }

  ngOnInit() {}

  async profile(){
    this.popover.dismiss();
    const modal = await this.modalController.create({
      component: MyProfileComponent,
      cssClass: 'profilePasswordComponent',
      backdropDismiss: false

    });
    modal.onDidDismiss()
    .then((data) => {
      
      let usercredentials = JSON.parse(localStorage.getItem("userData"));
      let email = usercredentials[0].email;
      this.verifyuser(email);
    });
  return await modal.present();
}

verifyuser(email){
  
  this.service.apicall('userlogin?user_email=' + email)
   .subscribe(
     resultArray => {
       
       let res:any = resultArray;
       let response = JSON.parse(res.body);
        if (response != [] && response != '') {
         let responsedata = response[0];
         if (responsedata.ID != '' && responsedata.ID != undefined) {
           
           localStorage.setItem("userData", JSON.stringify(response));
       }
       }
     });
   }

  logout(){
    localStorage.removeItem("role");
    localStorage.removeItem("name");
    localStorage.removeItem('socialuser');
    localStorage.removeItem("userData");
    localStorage.removeItem('accessToken');
    localStorage.removeItem('auth_token');
    this.popover.dismiss();
    this.router.navigate(['/login']);
  }

  async change_password(){
    this.popover.dismiss();
      const modal = await this.modalController.create({
        component: ChangePasswordComponent,
        cssClass: 'ChangePasswordComponent',
        backdropDismiss: false
  
      });
      modal.onDidDismiss()
        .then((data) => {
  
        });
      return await modal.present();
    }

}
