import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-alert-popup',
  templateUrl: './alert-popup.component.html',
  styleUrls: ['./alert-popup.component.scss'],
})
export class AlertPopupComponent implements OnInit {
type:any='';
message:any='';
  constructor(private modalCtrl:ModalController, private navParam:NavParams) {
    this.type=this.navParam.get('type');
    this.message=this.navParam.get('message');
    setTimeout(()=>{
      this.modalCtrl.dismiss();
    }, 2000)
   }

  ngOnInit() {}

}
