import { Injectable } from '@angular/core';
import * as moment_tz from 'moment-timezone'

@Injectable({
  providedIn: 'root'
})
export class FixDateOffsetService {

  constructor() { }

  // When the date is received it from the DB, we apply offset based on our timezone
  fixDateOffset(dateString: string): string {
    const format = "YYYY-MM-DDTHH:mm:ss.SSSZ";
    const timezone = "UTC"; // Assuming the datestring is in UTC

    // Parse the date string and add offset
    const originalDate = moment_tz(dateString, format).tz(timezone);
    const currMoment = moment_tz();
    const currOffset = -1 * currMoment.utcOffset();
    const newDate = originalDate.add(currOffset, 'minutes');

    // Format the new date as a string in the same format
    const newDateString = newDate.format(format);
    return newDateString
  }


}
